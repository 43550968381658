import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';

import { Section, Button, Title, Text, Box } from '../components/Core';
import PageWrapper from '../components/PageWrapper';
import Contact from '../sections/common/Contact';
import imgWorkCover from '../assets/image/jpg/details/details-1.jpg';
import imgS1 from '../assets/image/jpg/details/details-6.jpg';
import imgS2 from '../assets/image/jpg/details/details-5.jpg';
import imgS3 from '../assets/image/jpg/details/details-4.jpg';
import imgS4 from '../assets/image/jpg/details/details-3.jpg';
import imgS5 from '../assets/image/jpg/details/details-8.jpg';
import imgS6 from '../assets/image/jpg/details/details-7.jpg';

import testmain from '../assets/image/png/test-main.png';
import test1 from '../assets/image/png/test-1.png';
import test2 from '../assets/image/png/test-2.png';
import test3 from '../assets/image/png/test-3.png';
import test4 from '../assets/image/png/test-4.png';

import { device } from '../utils';

const WorkSingle = () => {
	return (
		<>
			<PageWrapper>
				<Container
					fluid
					className="px-sm-5"
					css={`
						margin-top: 92px;
					`}
				>
					<img src={testmain} alt="" className="img-fluid w-100" />
				</Container>
				<Section className="mt-lg-5">
					<Container>
						<Row>
							<Col lg="8">
								<Text variant="tag">BRANDING</Text>
								<Title variant="secSm" className="my-4">
									Prva pomoč pri najmlajših je priročnik za starše, ki
									kompleksno tematiko razlaga na bolj interaktiven način.{' '}
								</Title>
								<Text
									variant="p"
									css={`
										max-width: 750px;
									`}
								>
									celostna grafična podoba, tisk, ilustracija
								</Text>
							</Col>
						</Row>
					</Container>
				</Section>
				<div className="mt-lg-3">
					<Container>
						<Row>
							<Col lg="4" className="mb-4 mb-lg-0">
								<Text variant="tag">Naročnik</Text>
								<Title variant="cardBig" className="mt-3">
									Rdeči križ Slovenije
								</Title>
							</Col>
						</Row>
					</Container>
				</div>
				<Section className="mt-lg-5">
					<Container>
						<Row>
							<Col xs="6" className="mb-5">
								<img src={test3} alt="" className="img-fluid w-100" />
							</Col>
							<Col xs="6" className="mb-5">
								<img src={test2} alt="" className="img-fluid w-100" />
							</Col>
							<Col xs="6" className="mb-5 mb-lg-0 pr-lg-4">
								<img src={test1} alt="" className="img-fluid w-100" />
							</Col>
							<Col xs="6" className="pl-lg-4">
								<img src={test4} alt="" className="img-fluid w-100" />
							</Col>
						</Row>
					</Container>
				</Section>
				<Box py={4}>
					<Contact />
				</Box>
			</PageWrapper>
		</>
	);
};
export default WorkSingle;
